/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import { JSONMap as r } from "../../core/jsonMap.js";
import { JSONSupport as e } from "../../core/JSONSupport.js";
import { property as s } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { enumeration as t } from "../../core/accessorSupport/decorators/enumeration.js";
import { subclass as p } from "../../core/accessorSupport/decorators/subclass.js";
const c = new r({
  inherited: "inherited",
  codedValue: "coded-value",
  range: "range"
});
let a = class extends e {
  constructor(o) {
    super(o), this.name = null, this.type = null;
  }
};
o([s({
  type: String,
  json: {
    write: !0
  }
})], a.prototype, "name", void 0), o([t(c)], a.prototype, "type", void 0), a = o([p("esri.layers.support.Domain")], a);
const i = a;
export { i as default };